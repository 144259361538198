export const UPDATE_CONTACT_EMAIL = 'UPDATE_CONTACT_EMAIL';
export const UPDATE_COUNTRY = 'UPDATE_CONTRY';
export const UPDATE_FIRST_NAME_CONTACT = 'UPDATE_FIRST_NAME_CONTACT';
export const UPDATE_LAST_NAME_CONTACT = 'UPDATE_LAST_NAME_CONTACT';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_NAME_OF_SCHOOL_OR_ORGANISATION = 'UPDATE_NAME_OF_SCHOOL_OR_ORGANISATION';
export const UPDATE_CONTACT_TELEPHONE = 'UPDATE_CONTACT_TELEPHONE';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE'; 

export const updateContactEmail = email => ({
    type: UPDATE_CONTACT_EMAIL,
    email
});

export const updateContactFirstName = firstName => ({
    type: UPDATE_FIRST_NAME_CONTACT,
    firstName
});

export const updateContactLastName = lastName => ({
    type: UPDATE_LAST_NAME_CONTACT,
    lastName
});

export const updateNameOfSchoolOrOrganisation = nameOrOrganisation => ({
    type: UPDATE_NAME_OF_SCHOOL_OR_ORGANISATION,
    nameOrOrganisation
});

export const updateContactTelephone = telephone => ({
    type: UPDATE_CONTACT_TELEPHONE,
    telephone
});

export const updateCountry = country => ({
    type: UPDATE_COUNTRY,
    country
});

export const updateMessage = message => ({
    type: UPDATE_MESSAGE,
    message
});

export const postContactUsDetails = () => {
    return getState => {
        console.log(getState().contactUs.firstName)
    }
}

export const selectLanguage = language => ({
    type: SELECT_LANGUAGE,
    language
});