import { UPDATE_CONTACT_EMAIL, UPDATE_CONTACT_TELEPHONE, UPDATE_COUNTRY, UPDATE_FIRST_NAME_CONTACT, UPDATE_LAST_NAME_CONTACT, UPDATE_MESSAGE, UPDATE_NAME_OF_SCHOOL_OR_ORGANISATION } from "../actions/index";

export const initialState = {
    country: '',
    email: '',
    firstName: '',
    lastName: '',
    message: '',
    nameOrOrganisation: ''
};

const contactus = (state=initialState, action) => {
    switch (action.type) {
        case UPDATE_CONTACT_EMAIL:
            return {
                ...state,
                email: action.email
            };
        case UPDATE_FIRST_NAME_CONTACT:
            return {
                ...state,
                firstName: action.firstName
            };
        case UPDATE_LAST_NAME_CONTACT:
            return {
                ...state,
                lastName: action.lastName
            };
        case UPDATE_NAME_OF_SCHOOL_OR_ORGANISATION:
            return {
                ...state,
                nameOrOrganisation: action.nameOrOrganisation
            };
        case UPDATE_CONTACT_TELEPHONE:
            return {
                ...state,
                telephone: action.telephone
            };
        case UPDATE_COUNTRY:
            return {
                ...state,
                country: action.country
            };
        case UPDATE_MESSAGE:
            return {
                ...state,
                message: action.message
            };
        default:
            return state;
    }
}

export default contactus;