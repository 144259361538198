import { combineReducers } from "redux";

import language from "./language";
import contactus from "./contactus";

const rootReducer = combineReducers({
    contactus,
    language
});

export default rootReducer;