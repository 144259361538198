import { SELECT_LANGUAGE } from "../actions";

const language = (state={lang: ''}, action) => {
    if (action.type === SELECT_LANGUAGE) {
        return {
            ...state,
            lang: action.language
        }
    }

    return state;
}

export default language;